

.common-gradient-background {
  background: -moz-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100% !important;
  background: -webkit-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100% !important;
  background: -webkit-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100% !important;
  background: -o-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100% !important;
  background: -ms-linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100% !important;
  background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 98% / 200% 100% !important;
}


.circle {
    display: flex;
    width: 2rem;
    height: 2rem;
    background-color: gray;
    border-radius: 50%;
  }

  .selected-circle {
    display: flex;
    width: 2rem;
    height: 2rem;
    background-color: blue;
    border-radius: 50%;
  }

  .selected-card-border{

    border: solid 1px var(--color-pink) !important;

  }

  .deselected-card-border{

    border: solid 1px transparent !important;

  }
  
  .glow {
    box-shadow: 0px 0px 4px 4px rgba(255,255,255,25);
  }

.forceFocusWhite {
}
.forceFocusWhite:focus {
    color:var(--color-white) !important;
}

.drag-active-background-color {
  background-color:var( --color-tertiary-transparent) ;
}

.uploaded-file-name {
  color:var(--color-tertiary) !important;
}

.dropzone {
  border: 2px dotted gray;
  height: 200px;
  width: 100%;
  align-content: center;
  text-align: center;
  display: flex;
   align-items: center; 
   justify-content: center;
}

.custom-center {
  width: 100%;
  height: 100%;
  align-content: center;
  text-align: center;
  display: flex;
   align-items: center; 
   justify-content: center;
}

.delete-file-button {
  color:var(--color-pink) !important;
}


.top-z {
    z-index: 99999999;
}

.top-z-2 {
  z-index: 999999999;
}


.modalbg {
  position: fixed;
  font-family: Arial, Helvetica, sans-serif;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  z-index: 99999;
  display: block;
  pointer-events: none;

}
.purchase_form{

  background:black !important;
}
.modalbg:target {
  display: block;
  pointer-events: auto;
  background: rgba(4, 10, 30, .8);

}

.close {
  background: #606061;
  color: #FFFFFF;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 24px;
  text-decoration: none;
  font-weight: bold;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;

}

.fineprint {
  font-style: italic;
  font-size: 10px;
  color: #646;
}